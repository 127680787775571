//Dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import Storage from "../../services/localStorage.service";
import Layout from "../../components/layouts";
import Cookies from "js-cookie";


// Services
import GA from "../../services/ga.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as thankyouStyles from "./thankyou.module.scss"

// Assets
import rateSE from '../../images/RateUs-SE.png';
import rateDK from '../../images/RateUs-DK.png';
import rateUK from '../../images/RateUs-UK.png';

const Thankyou = ({ location, pageContext }) => {

    // Translation Function
    const { t } = useTranslation();

    let selection = Storage.getWithExpiry('selection') || null;
    const [order, setOrderInfo] = useState();
    if (!selection) {
        selection = { token: Storage.getWithExpiry('order_token') };
    }

    useEffect(() => {
        //Get Params
        const params = new URLSearchParams(location.search);
        const paymentMethod = params.get('centraPaymentMethod');
        const payload = params.get('payload');
        const klarnaOrder = params.get('klarna_order');
        const pmFields = {
            centraPaymentMethod: paymentMethod
        }

        if (paymentMethod === 'klarna') {
            pmFields["klarna_order"] = klarnaOrder
        } else {
            pmFields["payload"] = payload
        }

        if (selection) axios.defaults.headers.post['Accept'] = `*/*;api-token: ${selection.token}`;
        axios({
            method: 'post',
            url: `${process.env.CENTRA_URL}/payment-result`,
            data: { paymentMethodFields: pmFields }
        }).then((response) => {
            setOrderInfo(response.data.order);
            GA.onPurchase(response.data.order);
            flowboxCheckoutScript(response.data.order);
            const partnerIds = Cookies.get('_partnerads');
            if (partnerIds) callPartnerAds(response.data.order, partnerIds);
            //newsletterSignup(response.data.order);
            //voyado(response.data.order);
            Storage.setWithExpiry('order_token', response.data.token, 300000);
        }).catch((error) => {
            console.log(error);
        });

    }, [])

    const newsletterSignup = (order) => {
        axios({
            method: 'post',
            url: `${process.env.CENTRA_URL}/newsletter-subscription/`,
            data: {
                email: order.address.email,
                country: order.address.country,
                language: order.language
            }
        }).then((response) => {
            //console.log(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    const flowboxCheckoutScript = (order) => {
        const e = "//connect.getflowbox.com/bzfy-checkout.js";
        let o = document.createElement("script");
        o.type = "text/javascript"
        if (o.readyState) {
            o.onreadystatechange = function () {
                if (("loaded" === o.readyState || "complete" === o.readyState) && (o.onreadystatechange = null)) {
                    t();
                }
            }
        } else {
            o.onload = function () {
                t()
            }
        }
        o.src = e;
        document.getElementsByTagName("head")[0].appendChild(o);
        const t = () => {
            window.flowboxCheckout.checkout({
                apiKey: '29e435d7ed7695f15474faff8eff4186',
                products: order.items.map(item => {
                    return {
                        id: item.sku,
                        quantity: item.quantity
                    }
                }),
                orderId: order.order
            })
        }
    }

    const callPartnerAds = (order, ids) => {
        let programID = "";
        switch (pageContext.country) {
            case "se":
                programID = "8657"
                break;
            case "dk":
                programID = "8548"
                break;
            case "no":
                programID = "9120"
                break;
            default:
                programID = "8657"
                break;
        }
        const paid = ids.split('|')[0];
        const pacid = ids.split('|')[1];
        axios({
            method: 'get',
            url: `https://www.partner-ads.com/${pageContext.country}/leadtracks2s.php?programid=${programID}&type=salg&partnerid=${paid}&pacid=${pacid}&ordreid=${order.order}&varenummer=x&antal=1&omprsalg=${order.totals.grandTotalPriceAsNumber}`,
        }).then((response) => {
            Cookies.remove('_partnerads')
        }).catch((error) => {
            console.log(error);
            Cookies.remove('_partnerads')
        });
    }

    const voyado = (order) => {
        let store = 'EU';
        switch (order.address.country) {
            case "SE":
                store = "SE"
                break;
            case "DK":
                store = "DK"
                break;
            case "NO":
                store = "NO"
                break;
            default:
                store = "EU"
                break;
        }
        axios({
            method: 'post',
            url: `https://www.flowlife.com/.netlify/functions/voyado/`,
            data: {
                order: order,
                store: store
            }
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    //Remove selection after order complete
    useEffect(() => {
        localStorage.removeItem('selection');
    }, [order])

    const pricerunnerLang = {
        se: {
            domain: "se",
            img: rateSE
        },
        dk: {
            domain: "dk",
            img: rateDK
        },
        eu: {
            domain: "com",
            img: rateUK
        },
        no: {
            domain: "com",
            img: rateUK
        },
        nl: {
            domain: "com",
            img: rateUK
        }
    }

    return (
        <Layout country={pageContext.country} location={location}>

            <div className={thankyouStyles.cdThankyouPage}>
                <div className="cd-max-width">
                    <div className={thankyouStyles.cdThankyouContainer}>
                        {order ?
                            <div className={thankyouStyles.cdThankyouContent}>
                                <div className={thankyouStyles.cdThankyouImage}>
                                    <h3>{t('thankYou.thanks')}!</h3>
                                </div>
                                <div className={thankyouStyles.cdThankyouHeading}>
                                    <h2>{t('thankYou.placed')} (<span>#{order.order}</span>)</h2>
                                    <p>{t('thankYou.receipt')} {`${order.address.email}`}</p>
                                </div>
                                <a className={thankyouStyles.cdPricerunnerLink} href={`https://www.pricerunner.${pricerunnerLang[pageContext.country].domain}/vr/83535`} target="_blank">
                                    <img src={pricerunnerLang[pageContext.country].img} alt="Price Runner" />
                                </a>
                                <div>
                                    <h4>{t('thankYou.info')}</h4>
                                    <div className={thankyouStyles.cdThankyouBox}>
                                        <p><b>{`${order.address.firstName} ${order.address.lastName}`}</b>
                                            <span>{`${order.address.address1}`}, </span>
                                            <span>{`${order.address.city}`}, </span>
                                            <span>{`${order.address.phoneNumber}`}</span>
                                        </p>
                                    </div>
                                    <h4>{t('checkout.orderSum')}</h4>
                                    <div className={thankyouStyles.cdThankyouBox}>
                                        <div className={thankyouStyles.cdThankyouProducts}>
                                            <h5>{t('mobileMenu.products')}</h5>
                                            {order && order.items && order.items.map((item, index) => {
                                                return (
                                                    <div className={thankyouStyles.cdThankyouProduct} key={index}>
                                                        {item.product.media.standard[0] &&
                                                            <div className={`${thankyouStyles.cdThankyouProductImg} cd-background-img`}>
                                                                <img src={item.product.media.standard[0]} />
                                                            </div>
                                                        }
                                                        <div>
                                                            <h2>{item.product.name}</h2>
                                                            <p>{item.quantity} x {item.product.price}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {order.totals.itemsTotalPrice &&
                                            <div className={thankyouStyles.cdThankyouRow}>
                                                <span>{t('checkout.priceBeforeDiscount')}</span>
                                                <p>{`${order.totals.itemsTotalPrice}`}</p>
                                            </div>
                                        }

                                        {order.totals.totalDiscountPrice &&
                                            <div className={thankyouStyles.cdThankyouRow}>
                                                <span>{t('checkout.discount')}</span>
                                                <p>{`${order.totals.totalDiscountPrice}`}</p>
                                            </div>
                                        }
                                        {order.totals.grandTotalPriceTax &&
                                            <div className={thankyouStyles.cdThankyouRow}>
                                                <span>{t('checkout.tax')}</span>
                                                <p>{`${order.totals.grandTotalPriceTax}`}</p>
                                            </div>
                                        }

                                        {order.totals.shippingPrice &&
                                            <div className={thankyouStyles.cdThankyouRow}>
                                                <span>{t('marquee.shipping')}</span>
                                                <p>{`${order.totals.shippingPrice}`}</p>
                                            </div>
                                        }

                                        {order.totals.grandTotalPrice &&
                                            <div className={thankyouStyles.cdThankyouRow}>
                                                <span>{t('checkout.total')}</span>
                                                <p>{`${order.totals.grandTotalPrice}`}</p>
                                            </div>
                                        }

                                        {order.paymentMethod &&
                                            <div className={thankyouStyles.cdThankyouRow}>
                                                <span>{t('checkout.paymentMethod')}</span>
                                                <p>{`${order.paymentMethod}`}</p>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            :
                            <h3>{t('thankYou.loading')}...</h3>
                        }
                    </div>
                </div>
            </div>
        </Layout>

    )

}

export default Thankyou